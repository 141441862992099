import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginPageComponent } from './login/login-page/login-page.component';
import { AdminComponent } from './admin/admin.component';
import { MsalGuard } from '@azure/msal-angular';

const routes: Routes = [
  { path: '', component: LoginPageComponent },
  { path: 'admin', pathMatch: 'full', component: AdminComponent },
  {
    path: 'order',
    pathMatch: 'full',
    loadChildren: () =>
      import('./order/order.module').then((m) => m.OrderModule),
    canActivate: [MsalGuard],
  },
  {
    path: 'template',
    loadChildren: () =>
      import('./template/template.module').then((m) => m.TemplateModule),
    canActivate: [MsalGuard],
  },
  {
    path: 'order',
    loadChildren: () =>
      import('./order/order-detail/order-detail.module').then(
        (m) => m.OrderDetailModule,
      ),
    canActivate: [MsalGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
