import { Injectable } from '@angular/core';
import { webSocket } from 'rxjs/webSocket';
import { catchError } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ProductHttpService } from './product-http.service';

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {

  private readonly websocketUrl = 'ws://localhost:8080/backend/ws';
  private webSocketSubject = webSocket<string>(this.websocketUrl);
  public webSocket$ = this.webSocketSubject.asObservable();

  private readonly messageProductProposalsInvalid = 'productProposals invalid';

  constructor(private productHttpService: ProductHttpService) {}

  openWs(): void {
    this.webSocket$
      .pipe(
        catchError((error) => {
          throw new Error(error);
        }),
        takeUntilDestroyed(),
      )
      .subscribe((message) => {
        if (message === this.messageProductProposalsInvalid) {
          this.notifyProductProposalCache();
        }
      });
  }

  private notifyProductProposalCache(): void {
    this.productHttpService.onProductInvalidation();
  }
}
